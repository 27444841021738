@import 'variables.scss';
@import 'mixins.scss';
@import 'animations.scss';


li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
    // font-family: Font2;
    // font-weight: bold;
}

* {
    transition: .5s all ease-in-out;
}

::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: $color-primary;
}

::-webkit-scrollbar-thumb {
    background: $color-secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-family;
}

p {
    font-family: $body-family;
}

section {
    background: rgba($color: $color-primary, $alpha: 1);
    padding: 5%;

    &:nth-child(even) {
        background: rgba($color: $color-secondary, $alpha: 0.9);
        // position: relative;

        // .title {
        //     margin-top: 5%;
        // }

        // &::before {
        //     content: "";
        //     background: rgba($color: $color-primary, $alpha: 1);
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     top: -20%;
        //     z-index: -1;
        //     rotate: 180deg;

        //     left: 0;
        //     clip-path: polygon(100% 100%, 0% 100%, 0.00% 80.00%, 2.00% 79.91%, 4.00% 79.65%, 6.00% 79.22%, 8.00% 78.64%, 10.00% 77.94%, 12.00% 77.13%, 14.00% 76.24%, 16.00% 75.31%, 18.00% 74.37%, 20.00% 73.45%, 22.00% 72.59%, 24.00% 71.81%, 26.00% 71.15%, 28.00% 70.62%, 30.00% 70.24%, 32.00% 70.04%, 34.00% 70.01%, 36.00% 70.16%, 38.00% 70.48%, 40.00% 70.95%, 42.00% 71.58%, 44.00% 72.32%, 46.00% 73.16%, 48.00% 74.06%, 50.00% 75.00%, 52.00% 75.94%, 54.00% 76.84%, 56.00% 77.68%, 58.00% 78.42%, 60.00% 79.05%, 62.00% 79.52%, 64.00% 79.84%, 66.00% 79.99%, 68.00% 79.96%, 70.00% 79.76%, 72.00% 79.38%, 74.00% 78.85%, 76.00% 78.19%, 78.00% 77.41%, 80.00% 76.55%, 82.00% 75.63%, 84.00% 74.69%, 86.00% 73.76%, 88.00% 72.87%, 90.00% 72.06%, 92.00% 71.36%, 94.00% 70.78%, 96.00% 70.35%, 98.00% 70.09%, 100.00% 70.00%);
        // } 

    }
}

.title {
    color: transparent;
    background: #000;
    -webkit-background-clip: text;
    text-shadow: 2px 5px 5px rgba(255, 255, 255, 0.3);
    margin-top: 10px;
    font-family: $header-family;
    font-size: 40px;
    text-align: center;
}

/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/

header {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 5%;
    left: 5%;
    // transform: translate(-50%, - 50%);
    background: rgba($color: #fff, $alpha: 0.7);
    backdrop-filter: blur(5px);
    z-index: 3000;
    border: 3px solid #fff;
    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.7);
    margin: auto;
    padding: 20px 0;
    border-radius: 80px;

    .header-logo {
        width: 20%;
        padding-left: 5%;

        // img {
        //     width: 60%;
        // }

        h1 {
            font-size: 50px;
            margin: 0;
            color: $color-secondary;
        }
    }

    .header-links {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding-right: 5%;
        width: 55%;
        margin-left: auto;
        position: relative;

        a {
            width: calc(100% / 4);
            font-size: 20px;
            font-family: $header-family;
            text-align: center;
            padding: 10px 10px;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 40px;
            font-weight: bold;

            &:hover {
                color: #fff;
                background: $gradient;
                transition: .5s all ease;
                // padding: 15px 20px;
            }

        }

        #buy-now {
            background: $gradient;
            color: #fff;
            border-radius: 40px;
            padding: 15px 40px;
            font-size: 20px;
            margin-right: 30px;
            text-align: center;
            font-family: $header-family;

        }


        .prod-dropdown {
            display: flex;
            flex-direction: column;
            color: #fff;
            // padding: 2% 4%;
            position: absolute;
            top: 150%;
            left: 20%;

            a {
                width: 100%;
                padding: 20px 20px;
                text-align: left;
                margin-bottom: 2%;
                font-size: 20px;
                margin-bottom: 5%;
                background: $gradient;
                border-radius: 0;
                box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.7);
                border: 3px solid #fff;
                border-radius: 40px;

                &:hover {
                    border-radius: 10px;
                    transform: scale(1.1);
                }

            }
        }

    }
}

.header-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    z-index: 3000;
    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.7);
    background: #fff;
    padding: 20px 0;

    .header-logo {
        width: 20%;
        padding-left: 5%;

        // img {
        //     width: 60%;
        // }

        h1 {
            font-size: 30px;
            margin: 0;
            color: $color-secondary;
        }
    }

    .menu {
        width: 40px;
        height: 40px;
        fill: $color-secondary;
        margin-right: 10%;
        margin-left: auto;
    }

    .header-links-mobile {
        position: fixed;
        top: 0;
        right: 0;
        width: 60%;
        height: 100vh;
        animation: slide-in-right 1s 1;
        background: $color-primary;
        display: flex;
        flex-direction: column;
        padding: 5%;
        box-shadow: -2px -2px 5px rgba($color: #000, $alpha: 0.4);

        .close {
            position: absolute;
            top: 5%;
            right: 10%;

            svg {
                width: 20px;
                height: 20px;
                fill: $red
            }
        }

        a {
            font-size: 18px;
            margin-bottom: 10%;
            color: $red;
            font-family: $header-family;
            // font-weight: bold
        }

        .h-l {
            margin-top: 100px;
        }

        .prod-dropdown {
            display: flex;
            flex-direction: column;
            margin-bottom: 5%;

            a {
                color: #fff;
                width: 70%;
                padding: 10px;
                text-align: left;
                margin-bottom: 2%;
                font-size: 16px;
                margin-bottom: 5%;
                background: $gradient;
                border-radius: 0;
                box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.7);
                border: 3px solid #fff;
                border-radius: 10px;

                &:hover {
                    transform: none;
                }

            }
        }
    }
}

.inner {
    background: #fff;
    top: 0;
    left: 0;
    border-radius: 0;
    width: 90%;
    padding: 1.5% 5%;
}


/*--------------------------------------------------------------
# Banner
--------------------------------------------------------------*/

.banner {
    // background: linear-gradient(90deg, #f6bf1a 0%, #ed1009 100%);
    background: url("../Images/Homepage/Untitled\ design.png");
    background-size: cover;
    margin: 0;
    padding: 7% 5% 5% 5%;
    display: flex;
    background-repeat: no-repeat;

    .banner-left {
        width: 50%;
        margin-top: 7%;

        h1 {
            margin: 0;
            font-family: $font-3;
            font-size: 40px;
            color: transparent;
            background: #000;
            -webkit-background-clip: text;
            text-shadow: 2px 5px 5px rgba(255, 255, 255, 0.3);
            margin-top: 10px;
            text-transform: capitalize;
            font-weight: bold;
        }

        p {
            line-height: 180%;
            font-size: 16px;
            font-family: $body-family;
            font-weight: 400;
            margin-bottom: 60px;
            color: rgba($color: #000, $alpha: 0.7);
        }

        a {
            font-size: 18px;
            text-transform: capitalize;
            border-radius: 40px;
            padding: 20px 50px;
            text-align: center;
            background: $color-secondary;
            color: #fff;
            border: 4px solid $color-primary;
            box-shadow: inset $box-shadow;
            font-family: $body-family;

            &:hover {
                box-shadow: $box-shadow;
                transition: .5s all ease;
            }
        }
    }

    .banner-right {
        width: 50%;
        margin-left: 5%;
        margin-top: 5%;
        position: relative;


        img {
            width: 80%;
            // box-shadow: $box-shadow;
            // border: 3px solid $color-primary;
            // border-radius: 30px;
            // background: #fff;
            filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
            margin-top: -25%;

            &:first-child {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                display: block;

            }

            &:last-child {
                position: absolute;
                top: 35%;
                left: 20%;
                z-index: 3;
            }
        }
    }
}



/*--------------------------------------------------------------
# Products
--------------------------------------------------------------*/

.products {
    background: rgba($color: $color-primary, $alpha: 1);
    padding: 5%;

    .products-body {
        display: flex;

        .products-card {
            width: calc(100% / 2);
            display: flex;
            justify-content: space-between;
            margin: 1%;
            position: relative;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.7);
            // height: 70vh;
            // border-radius: 20px;
            overflow: hidden;
            border: 4px solid #fff;


            &:hover {

                img {
                    transform: scale(1.1);
                }

            }

            .img {
                width: 45%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    // border-radius: 20px 20px 0 0;
                    // object-position: 0px -130px;

                }
            }


            .headline {
                // position: absolute;
                // left: 0;
                // bottom: 0%;

                background: #fff;
                // border-radius: 0 100px 100px 0;
                padding: 20px 20px;
                margin: 0;
                // box-shadow: inset $box-shadow;
                width: 60%;

                h4 {
                    margin: 0;
                    font-size: 25px;
                    font-family: $header-family;
                }

                p {
                    font-size: 16px;
                    color: rgba($color: #000, $alpha: 0.7);
                    line-height: 150%;
                    margin-bottom: 30px;
                    font-family: $body-family;
                }
            }

            a {
                font-size: 16px;
                text-transform: capitalize;
                // border-radius: 40px;
                padding: 10px 30px;
                text-align: center;
                background: $gradient;
                color: #fff;
                // border: 4px solid $color-primary;
                box-shadow: inset $box-shadow;
                font-family: $body-family;

                &:hover {
                    box-shadow: $box-shadow;
                    transition: .5s all ease;
                }
            }
        }
    }
}


/*--------------------------------------------------------------
# Buy Now
--------------------------------------------------------------*/

.buy {
    background: rgba($color: $color-secondary, $alpha: 0.9);
    padding: 5%;

    .buy-body {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        img {
            width: 48%;
            object-fit: contain;
            margin-top: -5%;
            margin-right: 5%;
        }

        form {
            width: 60%;

            display: flex;
            flex-direction: column;

            .input {
                display: flex;

                input {
                    width: calc(100% / 2);
                }
            }

            input,
            select {
                background: rgba($color: #fff, $alpha: 0.9);
                border: none;
                padding: 20px 20px;
                margin: 2% 1%;
                font-size: 16px;
                outline: none;
                border-radius: 10px;
                font-family: $body-family;

                &::placeholder {
                    color: #000;
                }
            }

            button {
                background: $gradient;
                border: 2px solid $color-primary;
                // width: 200px;
                text-align: center;
                font-size: 18px;
                margin: 1%;
                padding: 20px 30px;
                font-family: $body-family;
                border-radius: 20px;
                box-shadow: inset 2px 2px 5px rgba($color: #000, $alpha: 0.3);

                &:hover {
                    box-shadow: $box-shadow;
                }
            }
        }
    }


}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
footer {
    background: $color-primary;
    // background: $color-primary;
    // background: linear-gradient(30deg, $color-primary 0%, $color-secondary 100%);
    // background: $gradient;
    padding: 3% 10% 1% 10%;
    color: $red;
    // background: $color-primary;

    .footer-top {

        display: flex;
        justify-content: space-between;

        .footer-subscribe {
            width: 50%;

            h3 {
                font-family: $header-family;
                font-size: 24px;
                text-transform: capitalize;
            }

            p {
                font-size: 18px;
                font-family: $header-family;
            }

            .subscribe {
                margin-top: 5%;
                display: flex;


                input {
                    background: transparent;
                    padding: 1% 2%;
                    border: 1px solid $red;
                    border-radius: 4px;
                    width: 80%;
                    margin-right: 3%;

                    &::placeholder {
                        color: $red;
                    }
                }

                button {
                    background: $red;
                    color: #fff;
                    padding: 2%;
                    width: 20%;
                    border: none;
                    border-radius: 4px;
                    font-family: $header-family;
                }
            }

            .footer-follow {
                margin-top: 5%;
                display: flex;

                a {
                    margin-right: 3%;
                    background: $gradient;
                    width: 50px;
                    height: 50px;
                    position: relative;
                    display: block;
                    border-radius: 10px;
                    backdrop-filter: blur(5px);
                    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
                    border: 1px solid #fff;

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 25px;
                        height: 25px;

                        path {
                            fill: #fff;
                        }
                    }
                }

            }
        }

        .footer-contact {
            width: 30%;
            // box-shadow: inset 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
            border-radius: 10px;
            backdrop-filter: blur(1px);
            // padding: 3%;

            h4 {
                // color: #fff;
                font-weight: bold;
                font-family: $header-family;
                font-size: 30px;
                margin-bottom: 5%;
                margin-top: 0%;
            }

            ul {
                padding: 0;

                li {
                    // color: rgba($color:  rgba($color: $color-accent, $alpha: 0.7), $alpha: 0.6) ;
                    font-family: $header-family;
                    // font-weight: 100;
                    line-height: 200%;
                    display: flex;
                    align-items: baseline;
                    font-family: $body-family;
                    font-size: 16PX;

                    .svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 5%;

                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: $red;
                    }

                    a {
                        text-decoration: none;
                        font-family: $body-family;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .footer-copyright {
        border-top: 1px solid rgba($color: $red, $alpha: 0.6);
        margin-top: 3%;
        padding: 1% 2%;
        display: flex;
        align-items: center;

        p {
            font-family: $header-font;
        }

        .copy {
            margin-left: auto;
            width: 30%;

            a {
                border-right: 1px solid #000;
                font-family: $header-font;
                background: $red;
                color: #fff;
                padding: 2% 4%;
                border-radius: 4px;
                text-align: center;

                &:last-child {
                    border-right: 0;
                    // padding-left: 2%;
                }
            }
        }
    }

}

/*--------------------------------------------------------------
# murine300
--------------------------------------------------------------*/
.murine300 {

    section {
        padding: 5%;
        background: rgba($color: $color-secondary, $alpha: 0.3);

        &:nth-child(even) {
            background: rgba($color: $color-primary, $alpha: 0.1);
        }
    }
}


.product-images {
    // margin-top: 10%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    background: $color-secondary;
    padding-top: 4%;

    img {
        width: calc(100% / 2.2);
        // background: $color-secondary;
    }

    svg {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: rgba($color: #fff, $alpha: 0.9);
        width: 60px;
        height: 60px;
        rotate: 45deg;
    }
}

.about {


    .about-body {
        h2 {
            font-size: 40px;
            text-transform: capitalize;
            color: $red;
            font-family: $font-3;
        }

        .tagline {
            font-style: italic;
            font-size: 20px;
            color: #000;
            margin-bottom: 2%;
        }

        p {
            color: rgba($color: #000, $alpha: 0.7);
            line-height: 180%;
            font-size: 16px;
            margin-bottom: 3%;
        }

        a {
            background: $color-secondary;
            color: #fff;
            padding: 10px 40px;
            border-radius: 5px;
            font-size: 22px;
            box-shadow: inset $box-shadow;
            font-family: $body-family;
        }
    }
}

.mechanism {
    background: url("../Images/Homepage/12671.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;


    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
        border-radius: 50%;

        svg {
            width: 30px;
            height: 30px;
            fill: $color-secondary;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .alice-carousel__prev-btn {
        transform: scaleX(-1);
        left: -2%;
        margin-top: -1%;
    }

    .alice-carousel__next-btn {
        right: -3%;
    }


    .mechanism-body {

        p {
            color: rgba($color: #000, $alpha: 0.7);
            line-height: 180%;
            font-size: 16px;
            margin-bottom: 3%;

            button {
                color: $color-secondary !important;
            }
        }

        .mechanism-option {

            .mechanism-button {
                display: flex;
                justify-content: center;
                align-items: stretch;
                margin-bottom: 20px;

                .active {
                    background: $color-secondary;
                    color: #fff;
                }

                button {
                    padding: 20px;
                    width: calc(100% / 3);
                    font-size: 20px;
                    color: $color-secondary;
                    border: none;
                    background: rgba($color: $color-primary, $alpha: 0.9);
                    box-shadow: inset $box-shadow;
                    cursor: pointer;
                    border-radius: 10px;
                    outline: none;

                    &:hover {
                        box-shadow: $box-shadow;
                    }

                    &:nth-child(2) {
                        margin: 0 2%;
                    }

                }
            }


            .mechanism-option-body {
                display: flex;
                justify-content: center;
                align-items: stretch;
                padding: 100px 30px 0 30px;
                position: relative;

                h3 {
                    position: absolute;
                    top: 0;
                    left: 3%;
                    background: $color-secondary;
                    padding: 20px 30px;
                    z-index: 2000;
                    color: #fff;
                    width: 90%;
                    border-radius: 50px;
                    box-shadow: inset $box-shadow;
                    text-align: center;
                    font-size: 25px;

                }


                .mechanism-card {
                    width: calc(100% /5);
                    // width: calc(100% / 5);
                    background: $color-primary;
                    padding: 20px 20px;
                    margin: .5%;
                    border-radius: 20px;
                    backdrop-filter: blur(3px);
                    box-shadow: inset $box-shadow;

                    p {
                        font-size: 16px;
                        margin: 0;
                    }

                    h4 {
                        font-size: 20px;
                        margin-bottom: 2%;
                        margin-top: 0;
                        text-transform: capitalize;
                    }

                    &:first-child {
                        margin-left: 0;
                        // background: rgba($color: #d2f6ff, $alpha: 0.7);
                        background: #d5e9ff;
                    }

                    &:nth-child(2) {
                        // background: rgba($color: #d2ddff, $alpha: 0.7);
                        background: #d2ddff;

                    }


                    &:nth-child(3) {
                        // background: rgba($color: #e2d2ff, $alpha: 0.7);
                        background: #e2d2ff;

                        margin-right: 0;
                    }

                    &:nth-child(4) {
                        background: #fff8d2;

                        // background: rgba($color: #fff8d2, $alpha: 0.7);
                    }

                    &:nth-child(5) {
                        background: #ffe5d2;

                        // background: rgba($color: #ffe5d2, $alpha: 0.7);
                    }

                    // &:nth-child(4),
                    // &:last-child {
                    //     width: 44%;

                    // }
                }

            }

            .omega,
            .lycopene {

                .mechanism-card {
                    width: calc(100% /4);

                }
            }

            .noname {
                .mechanism-card {
                    width: 100%;
                    background: #ede2ff !important;
                }
            }
        }

    }

    .mechanism-body-mobile {
        display: none;

        p {
            color: rgba($color: #000, $alpha: 0.7);
            line-height: 180%;
            font-size: 14px;
            margin-bottom: 3%;

            button {
                color: $color-secondary !important;
            }
        }

        .mechanism-button {
            // display: flex;
            // justify-content: center;
            // align-items: stretch;
            margin-bottom: 20px;

            .active {
                background: $color-secondary;
                color: #fff;
            }

            button {
                padding: 10px;
                // width: calc(100% / 3);
                font-size: 16px;
                width: 100%;
                color: $color-secondary;
                border: none;
                background: #fff;
                box-shadow: inset $box-shadow;
                cursor: pointer;
                border-radius: 10px;
                outline: none;
                margin-bottom: 2%;

                &:hover {
                    box-shadow: $box-shadow;
                }

            }
        }



        .mechanism-option-body {
            // display: flex;
            // justify-content: center;
            // align-items: stretch;
            // padding: 100px 30px 0 30px;
            position: relative;

            h3 {
                position: absolute;
                top: 0;
                left: 3%;
                background: $color-secondary;
                padding: 20px 30px;
                z-index: 2000;
                color: #fff;
                width: 90%;
                border-radius: 50px;
                box-shadow: inset $box-shadow;
                text-align: center;
                font-size: 25px;

            }


            .mechanism-card {
                width: 90%;
                // width: calc(100% /5);
                // width: calc(100% / 5);
                background: $color-primary;
                padding: 20px;
                margin: 3% 0;
                border-radius: 20px;
                backdrop-filter: blur(3px);
                box-shadow: inset $box-shadow;

                p {
                    font-size: 14px;
                    margin: 0;
                }

                h4 {
                    font-size: 16px;
                    margin-bottom: 2%;
                    margin-top: 0;
                    text-transform: capitalize;
                }

                &:first-child {
                    margin-left: 0;
                    // background: rgba($color: #d2f6ff, $alpha: 0.7);
                    background: #d5e9ff;
                }

                &:nth-child(2) {
                    // background: rgba($color: #d2ddff, $alpha: 0.7);
                    background: #d2ddff;

                }


                &:nth-child(3) {
                    // background: rgba($color: #e2d2ff, $alpha: 0.7);
                    background: #e2d2ff;

                    margin-right: 0;
                }

                &:nth-child(4) {
                    background: #fff8d2;

                    // background: rgba($color: #fff8d2, $alpha: 0.7);
                }

                &:nth-child(5) {
                    background: #ffe5d2;

                    // background: rgba($color: #ffe5d2, $alpha: 0.7);
                }

            }

        }

    }
}


.dosage {

    &::before {
        display: none !important;
    }

    .dosage-body {
        display: flex;

        .dosage-card {
            width: calc(100% / 4);
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);
            // padding: 2%;
            margin: 1%;
            border: 4px solid $color-secondary;
            overflow: hidden;


            .img {
                // clip-path: circle(77.5% at 51% 10%);
                // box-shadow: $box-shadow;
                filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    margin-bottom: 10%;
                    background: #e6e6e6;
                    border-radius: 20px;

                }
            }

            .dosage-card-text {
                // background: $pink;
                padding: 30px 10px;
                margin-top: -45px;
                background: transparent;

                h4 {
                    font-size: 20px;
                    // color: $color-secondary;
                    margin: 0;
                    margin-bottom: 3%;
                }

                p {
                    color: rgba($color: #000, $alpha: 0.7);
                    font-size: 16px;
                    line-height: 160%;
                    margin: 0;
                }
            }


        }
    }
}

.salt {
    background: url("../Images/Homepage/salt.jpeg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 60vh;
    background-position: center;



    .salt-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;


        p {
            background: rgba($color: #fff, $alpha: 0.5);
            backdrop-filter: blur(3px);
            box-shadow: $box-shadow;
            height: 10vh;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            width: calc(100% /5.3);
            text-align: center;
            line-height: 180%;
            border-radius: 20px;
            margin: .5%;
            margin-top: 15%;
            font-size: 16px;
            text-transform: capitalize;
            // height: 5vh;
        }
    }
}

.indications {

    .indications-body {
        display: flex;
        justify-content: center;
        align-items: stretch;
        // flex-wrap: wrap;
        overflow: hidden;

        .indications-card {
            width: calc(100% / 5);
            display: flex;
            justify-content: center;
            align-items: stretch;
            height: 70vh;
            position: relative;
            cursor: pointer;



            span {
                position: absolute;
                left: -75%;
                top: 45%;
                background: $color-secondary;
                font-size: 20px;
                color: #fff;
                font-weight: bold;
                text-transform: uppercase;
                padding: 10px 30px;
                width: 62vh;
                rotate: 90deg;
                text-align: center;
                z-index: 100;
            }

            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }


            .indications-card-text {
                width: 90%;
                padding: 5%;
                // display: none;
                background: rgba($color: #000, $alpha: 0.6);
                color: #fff;
                position: absolute;
                height: 100%;
                padding: 5%;
                display: none;
                backdrop-filter: blur(2px);

                h3 {
                    font-size: 20px;
                    margin: 0;
                }

                p {
                    font-size: 16px;
                }

                ul {
                    h6 {
                        font-size: 16px;
                        margin-left: -6%;
                        margin-bottom: 3%;
                    }

                    li {
                        font-size: 16px;
                        list-style-type: circle;
                        font-family: $body-family;
                        margin-bottom: 10px;
                        line-height: 180%;
                    }
                }
            }


        }
    }
}

.safety {
    .safety-body {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        .safety-card {
            width: calc(100% / 2.3);
            margin: 1%;
            background: rgba($color: $color-primary, $alpha: 0.4);
            border-radius: 10px;
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .safety-card-text {
                width: 80%;

                h3 {
                    font-size: 20px;
                    font-weight: 500;
                    margin: 0;
                    margin-bottom: 10px;
                }

                p {
                    color: rgba($color: #000, $alpha: 0.7);
                    font-size: 16px;
                    line-height: 160%;
                    margin: 0;
                }
            }



            svg {
                width: 80px;
                height: 80px;
            }
        }
    }
}


/*--------------------------------------------------------------
# murine XT
--------------------------------------------------------------*/
.murinext {
    section {

        &:nth-child(even) {
            background: rgba($color: $color-primary, $alpha: 0.3);
        }
    }

    .product-images {
        background: $pink;  
    }

    .mechanism {
        // .mechanism-body {

        //     .mechanism-option {
        //         display: flex;
        //         align-items: stretch;

        //         .mechanism-button {
        //             width: 20%;
        //             display: flex;
        //             flex-direction: column;

        //             button {
        //                 width: 100%;
        //                 background: $color-secondary;
        //                 color: #fff;
        //                 margin: 1%;
        //                 font-size: 20px;

        //                 &:last-child {
        //                     margin-bottom: 0;
        //                 }
        //             }

        //             .mactive {
        //                 background: $pink;
        //                 color: #fff;
        //             }
        //         }

        //         .mechanism-right {
        //             width: 80%;
        //             margin-left: 2%;
        //         }

        //         .mechanism-option-body {
        //             height: 96%;

        //             .mechanism-card {
        //                 width: calc(100% / 2.5);


        //             }

        //         }

        //         .cardiovascular {
        //             .mechanism-card {
        //                 width: calc(100% / 3.8) !important;
        //             }
        //         }

        //         .gynaecology {
        //             height: 96%;

        //             .mechanism-card {
        //                 width: calc(100% / 2.3) !important;

        //                 // &:last-child {
        //                 //     width: 100%!important;
        //                 // }
        //             }
        //         }

        //     }

        // }
        .mechanism-body {
            .mechanism-option {
                .mechanism-card {
                    height: 35vh;
                }
            }

            .cardiovascular {
                .mechanism-card {
                    width: calc(100% / 3);
                }
            }

            .gynaecology {
                .mechanism-card {
                    width: calc(100% / 4);
                }
            }

            .oncology {
                .mechanism-card {
                    width: calc(100% / 2);
                }
            }
        }

    }

    .indications {
        .indications-body {
            .indications-card {
                span {
                    left: -100%;
                }

            }
        }
    }

    .salt {
        .salt-body {

            margin-top: 10%;

            p {
                margin-top: 0;
                height: 10vh;

                width: calc(100% / 6.3);
            }
        }
    }

}

/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {

    header {
        display: none;
    }

    .header-mobile {
        display: flex;
    }

    .banner {
        flex-direction: column-reverse;
        padding-bottom: 40px;

        .banner-right {
            width: 100%;
            margin-top: 20%;
        }

        .banner-left {
            width: 100%;

            h1 {
                font-size: 20px;
            }

            p {
                font-size: 12px;
                // line-height: 130%;
                margin-bottom: 20px;
            }

            a {
                font-size: 14px;
                padding: 10px 30px;
            }
        }
    }

    .products {
        .products-body {
            flex-direction: column;

            .products-card {
                width: 96%;
                flex-direction: column;
                margin: 0;
                margin-bottom: 5%;

                .img {
                    width: 100%;
                    height: 40vh;
                }

                .headline {
                    width: 90%;

                    h4 {
                        font-size: 20px;
                    }

                    p,
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .buy {
        .buy-body {
            flex-direction: column;

            img,
            form {
                width: 100%;
            }

            form {
                margin-top: 5%;

                input,
                textarea,
                select,
                button {
                    font-size: 14px;
                    padding: 10px 20px;
                }


            }
        }
    }

    footer {
        padding: 5%;

        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-contact {
                width: 100%;

                .subscribe {
                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 14px;
                }

                ul {
                    li {
                        font-size: 14px;

                        a {
                            font-size: 14px;
                        }
                    }
                }
            }

            .footer-contact {
                margin-top: 10%;

                h4 {
                    font-size: 25px;
                }
            }
        }

        .footer-copyright {
            flex-direction: column;
            border-top: 1px solid #fff;
            font-size: 14px;

            .copy {
                width: 100%;
                font-size: 14px;
            }
        }
    }

    .title {
        font-size: 25px;
    }

    .product-images {
        margin-top: 17%;

        svg {
            width: 30px;
            height: 30px;
            bottom: 0;
        }
    }

    .about {
        .about-body {
            h2 {
                font-size: 30px;
            }

            .tagline {
                font-size: 16px;
            }

            p,
            a {
                font-size: 14px;
                margin-bottom: 30px;
            }
        }
    }

    .salt {
        height: 100%;
        background-position: 50% 10% !important;

        .salt-body {
            p {
                width: 100%;
                font-size: 14px;
                height: 100%;
                margin: 1%;
            }
        }
    }

    .murinext {
        .salt {
            height: 100%;
            background-position: 50% 10% !important;

            .salt-body {
                p {
                    width: 100%;
                    font-size: 14px;
                    height: 100%;
                    margin: 1%;
                }
            }
        }
    }


    .mechanism {
        .mechanism-body {
            display: none;
        }

        .mechanism-body-mobile {
            display: block;
        }
    }

    .dosage {
        .dosage-body {
            flex-direction: column;

            .dosage-card {
                width: 95%;

                .dosage-card-text {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .indications {

        .indications-body {
            flex-direction: column;

            .indications-card {
                width: 100%;
                overflow: hidden;
                margin-bottom: 3%;

                .indications-card-text {
                    ul {
                        li {
                            font-size: 14px;
                            // line-height: 140%;
                        }
                    }
                }

                span {
                    width: 90%;
                    left: 0;
                    top: 0;
                    // transform: translate(-50%, -300%);
                    rotate: 0deg;
                    font-size: 16px;
                }
            }
        }
    }

    .murine300 {
        .indications {
            .indications-body {
                .indications-card {
                    height: 50vh;
                }
            }
        }
    }

    .murinext .indications .indications-body .indications-card span {
        left: 0%;
        top: 0%;
        rotate: 0deg;
    }

    .safety {
        .safety-body {
            .safety-card {
                width: 100%;
                flex-direction: column;

                svg {
                    width: 50px;
                    height: 50px;
                    margin-right: auto;
                    margin-bottom: 5%;
                }

                .safety-card-text {
                    width: 100%;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    header {
        display: none;
    }

    .header-mobile {
        display: flex;

        .header-logo {
            h1 {
                font-size: 50px;
            }
        }
    }

    .banner {
        flex-direction: column-reverse;
        padding-bottom: 40px;

        .banner-right {
            width: 100%;
            margin-top: 20%;

            img {
                width: 60%;
            }
        }

        .banner-left {
            width: 100%;

            h1 {
                font-size: 20px;
            }

            p {
                font-size: 12px;
                line-height: 130%;
                margin-bottom: 20px;
            }

            a {
                font-size: 14px;
                padding: 10px 30px;
            }
        }
    }

    .products {
        .products-body {
            flex-direction: column;

            .products-card {
                width: 97%;
                // flex-direction: column;
                // margin: 0;
                // margin-bottom: 5%;

                .img {
                    // width: 100%;
                    height: 45vh;
                }

                .headline {
                    // width: 90%;

                    h4 {
                        font-size: 20px;
                    }

                    p,
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .buy {
        .buy-body {
            flex-direction: column;

            img,
            form {
                width: 100%;
            }

            form {
                margin-top: 5%;

                input,
                textarea,
                select,
                button {
                    font-size: 14px;
                    padding: 10px 20px;
                }


            }
        }
    }

    footer {
        padding: 5%;

        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-contact {
                width: 100%;

                .subscribe {
                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 14px;
                }

                ul {
                    li {
                        font-size: 14px;

                        a {
                            font-size: 14px;
                        }
                    }
                }
            }

            .footer-contact {
                margin-top: 10%;

                h4 {
                    font-size: 25px;
                }
            }
        }

        .footer-copyright {
            flex-direction: column;
            border-top: 1px solid #fff;
            font-size: 14px;

            .copy {
                width: 100%;
                font-size: 14px;
            }
        }
    }

    .title {
        font-size: 25px;
    }

    .product-images {
        margin-top: 14%;

        svg {
            width: 30px;
            height: 30px;
            bottom: 0;
        }
    }

    .about {
        .about-body {
            h2 {
                font-size: 30px;
            }

            .tagline {
                font-size: 16px;
            }

            p,
            a {
                font-size: 14px;
                margin-bottom: 30px;
            }
        }
    }

    .mechanism {
        .mechanism-body {
            display: none;
        }

        .mechanism-body-mobile {
            display: block;
        }
    }

    .salt {
        height: 100%;
        background-position: 50% 10% !important;

        .salt-body {
            p {
                width: 100%;
                font-size: 14px;
                height: 100%;
                margin: 1%;
            }
        }
    }

    .murinext {
        .salt {
            height: 100%;
            background-position: 50% 10% !important;

            .salt-body {
                align-items: stretch;

                p {
                    width: calc(100% / 2.5);
                    font-size: 14px;
                    // height: 100%;
                    height: 5vh;
                    margin: 1%;
                }
            }
        }
    }

    .murinext {
        .mechanism-body-mobile {
            .mechanism-button {
                button {
                    width: calc(100% / 2.03);

                    &:nth-child(even) {
                        margin-left: 1%;
                    }

                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }

    .dosage {
        .dosage-body {
            justify-content: center;
            flex-wrap: wrap;

            .dosage-card {
                width: calc(100% / 2.2);

                .dosage-card-text {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .indications {
        .indications-body {
            flex-direction: column;

            .indications-card {
                width: 100%;
                overflow: hidden;
                margin-bottom: 3%;

                span {
                    left: 0%;
                    top: 0%;
                    rotate: 0deg;
                    width: 95%;
                }
            }
        }
    }

    .murine300 {
        .indications {
            .indications-body {
                .indications-card {
                    height: 60vh;
                }
            }
        }
    }

    .murinext .indications .indications-body .indications-card span {
        left: 0%;
        top: 0%;
        rotate: 0deg;
    }


    .safety {
        .safety-body {
            .safety-card {
                width: 100%;
                flex-direction: column;

                svg {
                    width: 50px;
                    height: 50px;
                    margin-right: auto;
                    margin-bottom: 5%;
                }

                .safety-card-text {
                    width: 100%;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    header {
        display: none;
    }

    .header-mobile {
        display: flex;

        .header-logo {
            h1 {
                font-size: 50px;
            }
        }
    }

    .banner {
        flex-direction: column-reverse;
        padding-bottom: 40px;

        .banner-right {
            width: 100%;
            margin-top: 20%;
        }

        .banner-left {
            width: 100%;

            h1 {
                font-size: 20px;
            }

            p {
                font-size: 12px;
                line-height: 130%;
                margin-bottom: 20px;
            }

            a {
                font-size: 14px;
                padding: 10px 30px;
            }
        }
    }

    .products {
        .products-body {
            flex-direction: column;

            .products-card {
                width: 100%;
                flex-direction: column;
                margin: 0;
                margin-bottom: 5%;

                .img {
                    width: 100%;
                    height: 45vh;
                }

                .headline {
                    width: 90%;

                    h4 {
                        font-size: 20px;
                    }

                    p,
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .buy {
        .buy-body {
            flex-direction: column;

            img,
            form {
                width: 100%;
            }

            form {
                margin-top: 5%;

                input,
                textarea,
                select,
                button {
                    font-size: 14px;
                    padding: 10px 20px;
                }


            }
        }
    }

    footer {
        padding: 5%;

        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-contact {
                width: 100%;

                .subscribe {
                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 14px;
                }

                ul {
                    li {
                        font-size: 14px;

                        a {
                            font-size: 14px;
                        }
                    }
                }
            }

            .footer-contact {
                margin-top: 10%;

                h4 {
                    font-size: 25px;
                }
            }
        }

        .footer-copyright {
            flex-direction: column;
            border-top: 1px solid #fff;
            font-size: 14px;

            .copy {
                width: 100%;
                font-size: 14px;
            }
        }
    }

    .title {
        font-size: 25px;
    }

    .product-images {
        margin-top: 17%;

        svg {
            width: 30px;
            height: 30px;
            bottom: 0;
        }
    }

    .about {
        .about-body {
            h2 {
                font-size: 30px;
            }

            .tagline {
                font-size: 16px;
            }

            p,
            a {
                font-size: 14px;
                margin-bottom: 30px;
            }
        }
    }


    .mechanism {
        .mechanism-body {
            display: none;
        }

        .mechanism-body-mobile {
            display: block;
        }
    }

    .salt {
        height: 100%;
        background-position: 50% 10% !important;

        .salt-body {
            p {
                width: 100%;
                font-size: 14px;
                height: 100%;
                margin: 1%;
            }
        }
    }

    .murinext {
        .salt {
            height: 100%;
            background-position: 50% 10% !important;

            .salt-body {
                align-items: stretch;

                p {
                    width: calc(100% / 2.5);
                    font-size: 14px;
                    // height: 100%;
                    height: 5vh;
                    margin: 1%;
                }
            }
        }
    }

    .murinext {
        .mechanism-body-mobile {
            .mechanism-button {
                button {
                    width: calc(100% / 2.03);

                    &:nth-child(even) {
                        margin-left: 1%;
                    }

                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }

    .dosage {
        .dosage-body {
            // flex-direction: column;
            flex-wrap: wrap;

            .dosage-card {
                width: calc(100% / 2.2);

                .dosage-card-text {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .indications {
        .indications-body {
            flex-direction: column;

            .indications-card {
                width: 100%;
                overflow: hidden;
                margin-bottom: 3%;

                .indications-card-text {
                    ul {
                        li {
                            font-size: 14px;
                        }
                    }
                }

                span {
                    width: 80%;
                    left: 0%;
                    top: 0%;
                    rotate: 0deg;
                    width: 100%;
                }
            }
        }
    }

    .murine300 {
        .indications {
            .indications-body {
                .indications-card {
                    height: 50vh;
                }
            }
        }
    }

    .murinext .indications .indications-body .indications-card span {
        left: 0%;
        top: 0%;
        rotate: 0deg;
        width: 100%;
    }


    .safety {
        .safety-body {
            .safety-card {
                width: 100%;
                flex-direction: column;

                svg {
                    width: 50px;
                    height: 50px;
                    margin-right: auto;
                    margin-bottom: 5%;
                }

                .safety-card-text {
                    width: 100%;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

}

@media (min-width: 768px) and (max-width: $lg) {

    header {
        display: none;
    }

    .header-mobile {
        display: flex;

        .header-logo {
            width: 20%;

            h1 {
                font-size: 50px;
            }
        }
    }

    .banner {
        overflow: hidden;
        margin-top: 5%;

        .banner-right {
            width: 40%;
            margin-top: 10%;

            img {
                width: 100%;
            }
        }

        .banner-left {
            width: 60%;

            h1 {
                font-size: 30px;
            }

            p {
                font-size: 14px;
                // line-height: 130%;
                margin-bottom: 40px;
            }

            a {
                font-size: 16px;
                padding: 10px 30px;
            }
        }
    }

    .products {
        .products-body {

            .products-card {
                width: 97%;
                flex-direction: column;

                .img {
                    width: 100%;
                    height: 40vh;

                    img {
                        object-position: 20% 60%;
                    }
                }

                .headline {
                    width: 90%;
                }
            }
        }
    }

    footer {
        padding: 5% 5% 0 5%;

        .footer-top {
            padding-right: 5%;

            .footer-contact {
                width: 40%;
            }
        }


    }

    .title {
        font-size: 35px;
    }

    .product-images {
        margin-top: 5%;

        svg {
            bottom: 0;
            left: 47%;
        }
    }

    .mechanism {
        .mechanism-body {
            .mechanism-option {
                .mechanism-option-body {
                    .mechanism-card {
                        height: 50vh;

                        h4 {
                            font-size: 18px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }

            }
        }

        .mechanism-body-mobile {
            display: none;
        }
    }

    .murinext {
        .salt {
            height: 100%;
            background-position: 50% 10% !important;

            .salt-body {
                align-items: stretch;

                p {
                    width: calc(100% / 4);
                    font-size: 14px;
                    // height: 100%;
                    height: 5vh;
                    margin: 1%;
                }
            }
        }
    }

    .dosage {
        .dosage-body {
            justify-content: center;
            flex-wrap: wrap;

            .dosage-card {
                width: calc(100% / 2.2);

                .dosage-card-text {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .indications {
        .indications-body {
            flex-direction: column;

            .indications-card {
                width: 100%;
                overflow: hidden;
                margin-bottom: 3%;

                .indications-card-text {
                    width: 75%;
                    padding-left: 150px;
                }

                span {
                    left: 0%;
                    top: 0%;
                    rotate: 0deg;
                    width: 100%;
                }
            }
        }
    }

    .murinext .indications .indications-body .indications-card span {
        left: 0%;
        top: 0%;
        rotate: 0deg;
        width: 100%;
    }

    .safety {
        .safety-body {
            .safety-card {

                svg {
                    margin-right: 5%;
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: $xl) {
    .header-mobile {
        display: none;
    }

    .indications {
        .indications-body {
            .indications-card {
                .indications-card-text {
                    width: 80%;
                    padding-left: 100px;
                }
            }
        }
    }


}