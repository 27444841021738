@import url('https://fonts.googleapis.com/css2?family=Lato&family=Playfair+Display&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mate:ital@0;1&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@300..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
// colors
// $cream: #eae5d6;
// $yellow: #CC9D3C;
// $light-orange: #F99931;
// $orange: #f58432;
// $black: #343434;
// $grey: #585757;
// $blue: #282e40;

$red: #e16a3d;
$pink: #e29aad;
$color-primary: #fcd9cf;
$color-secondary: #e29340;

$header-family: "Roboto", sans-serif;
;
$body-family: "Merriweather", serif;

// gradient
$gradient: linear-gradient(90deg, $color-secondary 0%, $pink 55%);

// shadows
$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
$text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

// padding-margin
$padding: 0 5% 5% 5%;

// transition
$transition: .5s all ease;

// font-family
$header-font: 'Roboto', sans-serif;
$font-2: 'Lato', sans-serif;
$font-3: 'Playfair Display', serif;
$font-4: 'Poppins', sans-serif;